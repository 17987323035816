import React from 'react';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { graphql, useStaticQuery } from 'gatsby';
import { DesktopMobileGrid } from 'components/DesktopMobileGrid';
import { DesktopImageWrapper } from 'components/DesktopImageWrapper';
import { MobileImageWrapper } from 'components/MobileImageWrapper';

export const CzynszomatView = () => {
    const images = useStaticQuery(graphql`
        query CzynszomatImages {
            main: file(name: { eq: "czynszomat-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            mobile: file(name: { eq: "czynszomat-mobile" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            desktop: file(name: { eq: "czynszomat-desktop" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }
    `);

    const { main, desktop, mobile } = images;

    return (
        <ProjectMainLayout projectName={PROJECTS.czynszomat.name}>
            <DesktopMobileGrid
                main={main}
                projectName={PROJECTS.czynszomat.name}
                bgColor="czynszomat_bg"
                leftImage={<DesktopImageWrapper image={desktop} />}
                rightImage={<MobileImageWrapper image={mobile} />}
            />
        </ProjectMainLayout>
    );
};
